import React from "react"
import { graphql } from "gatsby"
import { htmlDecode } from "@utils"
import { Layout, SEO } from "@global"
import { CtaBillboard } from "@layouts"

export default ({ data }) => {
  const { tip } = data.wpgraphql
  return (
    <Layout>
      <SEO title={htmlDecode(tip.title)} />
      <section className="container mx-auto">
        <div className="px-4 pt-20 lg:pl-20 mx-auto">
          <h1 className="text-3xl leading-tight tracking-tight sm:text-4xl lg:text-5xl font-display text-center">
            {htmlDecode(tip.title)}
          </h1>
        </div>
      </section>
      <section className="container mx-auto px-4 py-10 lg:pl-20">
        <div className="max-w-3xl mx-auto">
          <div
            className="blog-post"
            dangerouslySetInnerHTML={{ __html: tip.content }}
          ></div>
        </div>
      </section>
      {/* End body */}
      <CtaBillboard
        headline="Looking for more information?"
        content="Simply reach out and we can help."
        alignment="center"
        backgroundColor="gradient"
        callToActionGroup={{
          ctaLinkText: "Contact Us",
          ctaLinkUrl: "/contact-us/",
          ctaButtonType: "primary",
        }}
        ctaBillboardSecondCallToActionCallToActionGroup={{
          ctaLinkText: "",
          ctaLinkUrl: "",
          ctaButtonType: "",
        }}
      />
    </Layout>
  )
}

export const TipQuery = graphql`
  query TipQuery($id: ID!) {
    wpgraphql {
      tip(id: $id) {
        uri
        id
        title
        content
      }
    }
  }
`
